import React, { useState, useEffect, Fragment } from "react"
import Img from "gatsby-image"
import "../layout.css"
import styled from "styled-components"
import { LiteYoutubeEmbed } from "react-lite-yt-embed"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { Container, Accordion, Button, Card, Col, Row } from "react-bootstrap"
import RowList from "../CountryList/RowList"
import { DotAccordion } from "./DotAccordion"
import testImg from "../../images/BT-1.png"
import { GenerateSgImg, SgHowToImage } from "./SgHowToImage"
import ReactPlayer from "react-player/lazy"

const CustomToggle = ({ children, eventKey, callback }) => {
  const currentEventKey = React.useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey
  return (
    <CustomButton
      rotate={isCurrentEventKey ? "rotate(90deg)" : "rotate(0deg)"}
      onClick={decoratedOnClick}
    >
      {children}
    </CustomButton>
  )
}

export const CustomAccordion = ({
  data,
  isRegional,
  countryList,
  isSg,
  isCountry,
  isHowTo,
  defaultKey,
  textCountries,
}) => {
  const [defaultActive, setDefaultActive] = useState(defaultKey)
  const [activeAccordion, setActiveAccordion] = useState(["uang", "0"])
  const [changeParent, setChangeParent] = useState("uang")

  useEffect(() => {
    setDefaultActive(defaultKey)
  }, [defaultKey])

  const handleSgImg = e => {
    setActiveAccordion(e)
  }

  const handleParent = e => {
    setChangeParent(e)
  }

  const BgColorDecider = () => {
    if (isSg) return "transparent"
    if (isHowTo) return "white"
  }

  return (
    <>
      <ContainerAccordion
        mt={isSg ? "0rem" : isCountry ? "3rem" : "5rem"}
        pl={isSg ? "0rem" : isCountry && "0"}
        plres={isSg && "0"}
      >
        <Accordion
          activeKey={defaultActive}
          id="accordionExample"
          onSelect={e => setDefaultActive(e)}
        >
          {data.map(
            (
              {
                idName,
                name,
                eventId,
                bodyData,
                video,
                videoLabel,
                customBody,
              },
              id
            ) => {
              return (
                <Fragment key={id}>
                  <a
                    id={idName}
                    style={{
                      display: "block",
                      paddingTop: "80px",
                      marginTop: "-80px",
                    }}
                  />
                  <CustomCard bcolor={(isSg || isCountry) && "transparent"}>
                    <CustomCardHeader bcolor={BgColorDecider()}>
                      <CustomToggle eventKey={eventId}>
                        <CustomTextHeader
                          fsize={isSg && "22px"}
                          color={(isSg && "#DA649F") || (isHowTo && "#102C57")}
                          onClick={e => handleParent(idName)}
                        >
                          {name}
                        </CustomTextHeader>
                      </CustomToggle>
                    </CustomCardHeader>
                    <Accordion.Collapse
                      eventKey={eventId}
                      style={{
                        backgroundColor: isSg || isHowTo ? "white" : "#0B7C8C",
                      }}
                    >
                      <div>
                        {countryList && (
                          <CustomCardBody
                            pad="0px 20px 0px 30px"
                            respad="0px 10px 0px 10px"
                            bshadow="none"
                            bcolor="transparent"
                          >
                            <UList>
                              <RowList data={idName} country={countryList} />
                            </UList>
                          </CustomCardBody>
                        )}
                        {isSg && (
                          <Row>
                            <Col md={8}>
                              <CustomCardBody
                                pad="40px 30px 30px 30px"
                                width="95%"
                                w576="95%"
                                bcolor="white"
                              >
                                <DotAccordion
                                  data={bodyData}
                                  idName={idName}
                                  sendToParent={handleSgImg}
                                  isSg
                                />
                                <VideoOnStep
                                  id={video}
                                  idName={idName}
                                  label={videoLabel}
                                />
                              </CustomCardBody>
                            </Col>
                            <Col md={4}>
                              <GenerateSgImg
                                data={activeAccordion}
                                changeParent={changeParent}
                              />
                            </Col>
                          </Row>
                        )}
                        {isHowTo && (
                          <>
                            <CustomCardBody
                              pad="40px 30px 30px 30px"
                              w576="95%"
                            >
                              {customBody && customBody}
                              {bodyData && (
                                <DotAccordion data={bodyData} isHowTo />
                              )}
                              {video && (
                                <VideoOnStep
                                  id={video}
                                  idName={idName}
                                  label={videoLabel}
                                />
                              )}
                            </CustomCardBody>
                          </>
                        )}
                      </div>
                    </Accordion.Collapse>
                  </CustomCard>
                </Fragment>
              )
            }
          )}
        </Accordion>
      </ContainerAccordion>
    </>
  )
}

const VideoOnStep = ({ id, idName, label }) => {
  const isTransfer = idName == "uang" || idName == "transfer"
  const isTopup = idName == "topup" || idName == "wallet"
  const isBill = idName == "tagihan" || idName == "bills"
  const isVerified = idName == "verified"
  return (
    <Container className="p-0">
      <Row className="mt-4">
        <Col md={3}>
          <CustomTextVideo>{label} </CustomTextVideo>
        </Col>
        <Col md={9}>
          <SpanBox bcolor="transparent" />
          <div style={{ width: "100%", height: "auto" }}>
            {isTransfer ? (
              <CustomReactPlayer
                url="https://www.youtube.com/watch?v=eh-US0EEtBI"
                width="100%"
                style={{
                  paddingLeft: "20px",
                }}
              />
            ) : (
              <LiteYoutubeEmbed id={id} style={{ paddingLeft: "20px" }} />
            )}
          </div>
        </Col>
      </Row>
    </Container>
  )
}

const ContainerAccordion = styled(Container)`
  margin-top: ${p => p.mt};
  padding-left: ${p => p.pl};
  padding-top: 50px;
  @media (max-width: 576px) {
    margin-top: ${p => p.mtres};
    padding-left: ${p => p.plres};
    margin: ${p => p.margin576};
  }
`

const CustomTextVideo = styled.h5`
  color: #102c57;
  font-family: "Typefez-bold";
  font-size: 24px;
  margin: 30px 0px;
  @media (max-width: 768px) {
    font-size: 20px;
    text-align: center;
  }
`

const SpanBox = styled.div`
  position: absolute;
  left: 0;
  top: 30px;
  width: 35px;
  height: 82px;
  background-color: ${p => p.bcolor || "#db64a0"};
`

const CustomCardHeader = styled(Card.Header)`
  background-color: ${p => p.bcolor || "#0b7c8c"};
  border: 0px;
  padding: 0px 20px;
`

const CustomCard = styled(Card)`
  background-color: ${p => p.bcolor};
  border: 0px;
  margin-bottom: 2rem;
`

const CustomCardBody = styled(Card.Body)`
  background-color: ${p => p.bcolor || "transparent"};
  box-shadow: ${p => p.bshadow || "1px 1px 15px rgba(73, 171, 184, 0.2)"};
  border-radius: 15px;
  margin: ${p => p.margin || "15px"};
  width: ${p => p.width || "70%"};
  padding: ${p => p.pad};
  @media (max-width: 992px) {
    width: ${p => p.w992};
  }
  @media (max-width: 768px) {
    width: ${p => p.w768};
  }
  @media (max-width: 576px) {
    width: ${p => p.w576 || "90%"};
    padding: ${p => p.respad};
    margin: ${p => p.margin576};
  }
`

const CustomButton = styled(Button)`
  padding: 0px;
  background-color: transparent;
  border: none;
  :hover,
  :focus,
  :active {
    outline: none !important;
    box-shadow: none !important;
    text-decoration: none !important;
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
  }
  ::before {
    transition: all 0.2s linear;
    transform: ${p => p.rotate};
    font-family: FontAwesome;
    content: "\f0da";
    font-size: 24px;
    position: absolute;
    left: 4px;
    top: -5px;
    color: #da649f;
  }
`

const CustomTextHeader = styled.h3`
  font-family: "Typefez-extrabold";
  font-size: ${p => p.fsize || "24px"};
  color: ${p => p.color || "white"};
  text-decoration: underline;
  @media (max-width: 992px) {
    text-align: left;
  }
  @media (max-width: 576px) {
    font-size: 21px;
  }
`

const UList = styled.ul`
  list-style: none;
  column-count: 4;
  font-family: "GothamMedium";
  @media (max-width: 992px) {
    column-count: 3;
  }
  @media (max-width: 768px) {
    column-count: 2;
  }
`

const CustomTextTerm = styled.p`
  color: ${p => p.color || "#48a9b5"};
  font-size: ${p => p.fs || "14px"};
  text-align: right;
  width: ${p => p.width || "70%"};
  font-family: "GothamBook";
  @media (max-width: 576px) {
    font-size: 12px;
    width: 95%;
  }
`

const CustomActiveTerm = styled.p`
  color: ${p => p.color || "#828282"};
  font-size: ${p => p.fs || "14px"};
  text-align: ${p => p.align || "left"};
  width: ${p => p.width || "70%"};
  font-family: "GothamBook";
  margin: 0px 0px;
  ::before {
    margin-right: 5px;
    font-family: FontAwesome;
    content: "\f111";
    color: ${p => p.contentColor || "#CACACA"};
    padding-left: 10px;
  }
  @media (max-width: 576px) {
    font-size: 10px;
    width: auto;
  }
`

const CustomDivDot = styled.div`
  width: 40%;
  position: absolute;
  bottom: 75px;
  right: -12%;
  @media (max-width: 576px) {
    display: none;
    /* width: 60%;
    display: flex;
    right: -10%;
    bottom: 12%; */
  }
`

const CustomDivDotRes = styled.div`
  display: flex;
  width: 95%;
  justify-content: flex-end;
  @media (min-width: 576px) {
    display: none;
  }
`
const CustomReactPlayer = styled(ReactPlayer)`
  height: 280px !important;
  @media (max-width: 576px) {
    height: 135px !important;
  }
`

import styled from "styled-components"
import { Link } from "gatsby"
import bgCity from "../../images/countries-city.svg"

export const CountriesSection = styled.section`
  position: relative;
  padding-bottom: 400px;
  background-image: url(${bgCity});
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
  @media (max-width: 414px) {
    padding-bottom: 185px;
  }
`

export const ListContinent = styled.h6`
  color: #48a9b5;
  font-family: "GothamMedium";
  padding-bottom: 1rem;
  text-align: center;
  /* border-bottom: 1px solid #48a9b5; */
  position: relative;
  ::after {
    content: "";
    width: 30px;
    height: 2px;
    background-color: #48a9b5;
    position: absolute;
    bottom: 9px;
    left: calc(50% - 15px);
  }
`
export const ActiveP = styled.p`
  font-family: "GothamBook";
  margin-bottom: 0;
  display: block;
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  font-size: 0.8rem;
  color: #48a9b5 !important;
`
export const ActiveSpan = styled.span`
  background-color: #196e97;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
`

export const ActiveSoonP = styled.p`
  font-family: "GothamBook";
  display: block;
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  font-size: 0.8rem;
  color: #48a9b5 !important;
`
export const ActiveSoonSpan = styled.span`
  background-color: #939598;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
`

export const ActiveSmall = styled.small`
  font-family: "GothamBook";
  color: #48a9b5;
  font-size: 80%;
  font-weight: 400;
`

export const UList = styled.ul`
  list-style: none;
  column-count: 4;
  font-family: "GothamMedium";
  @media (max-width: 414px) {
    column-count: 2;
  }
`

export const MutedLi = styled.li`
  color: #A6A6AC;
  font-family: "Typefez-med";
`

export const ActiveLi = styled.li`
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
`

export const ActiveLink = styled(Link)`
  color: white;
  font-family: "Typefez-bold";
  :hover {
    text-decoration: underline;
    color: white;    
  }
`

export const AvailableLi = styled.li`
  color: #A6A6AC;
  font-family: "Typefez-bold";
`

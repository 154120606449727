import React from "react"
import { useLocation } from "@reach/router"
import { MutedLi, ActiveLi, AvailableLi, ActiveLink } from "./CountryStyled"

function RowList({ country, data }) {
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")
  return (
    <>
      {country.map(detail => {
        const {
          id,
          Active,
          Available,
          slug,
          country_en,
          country_id,
          continent_en,
        } = detail
        if (Active && Available && continent_en === data) {
          return (
            <ActiveLi key={id}>
              <ActiveLink
                to={
                  isEnglish
                    ? `/en/countries/send-money-to-${slug}/`
                    : `/countries/send-money-to-${slug}/`
                }
              >
                {isEnglish ? country_en : country_id}
              </ActiveLink>
            </ActiveLi>
          )
        } else if (!Active && Available && continent_en === data) {
          return (
            <AvailableLi key={id}>
              {isEnglish ? country_en : country_id}
            </AvailableLi>
          )
        } else if (continent_en === data) {
          return (
            <MutedLi key={id}>{isEnglish ? country_en : country_id}</MutedLi>
          )
        }
      })}
    </>
  )
}

export default RowList

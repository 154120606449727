import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { useLocation } from "@reach/router"

export const GenerateSgImg = ({ data, changeParent }) => {
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")
  const {
    KU1,
    KU2,
    KU3,
    KU4,
    KU5,
    KU6,
    KU7,
    KU8,
    KU9,
    KUEN1,
    KUEN2,
    KUEN3,
    KUEN4,
    KUEN5,
    KUEN6,
    KUEN7,
    KUEN8,
    KUEN9,
    BT1,
    BT2,
    BT3,
    BT4,
    BT5,
    BT6,
    BT7,
    BTEN1,
    BTEN2,
    BTEN3,
    BTEN4,
    BTEN5,
    BTEN6,
    BTEN7,
    TU1,
    TU2,
    TU3,
    TU4,
    TU5,
    TU6,
    TUEN1,
    TUEN2,
    TUEN3,
    TUEN4,
    TUEN5,
    TUEN6,
  } = SgHowToImage()

  const TopUpImg1 = isEnglish
    ? TUEN1.childImageSharp.fluid
    : TU1.childImageSharp.fluid
  const TopUpImg2 = isEnglish
    ? TUEN2.childImageSharp.fluid
    : TU2.childImageSharp.fluid
  const TopUpImg3 = isEnglish
    ? TUEN3.childImageSharp.fluid
    : TU3.childImageSharp.fluid
  const TopUpImg4 = isEnglish
    ? TUEN4.childImageSharp.fluid
    : TU4.childImageSharp.fluid
  const TopUpImg5 = isEnglish
    ? TUEN5.childImageSharp.fluid
    : TU5.childImageSharp.fluid
  const TopUpImg6 = isEnglish
    ? TUEN6.childImageSharp.fluid
    : TU6.childImageSharp.fluid

  const KirimImg1 = isEnglish
    ? KUEN1.childImageSharp.fluid
    : KU1.childImageSharp.fluid
  const KirimImg2 = isEnglish
    ? KUEN2.childImageSharp.fluid
    : KU2.childImageSharp.fluid
  const KirimImg3 = isEnglish
    ? KUEN3.childImageSharp.fluid
    : KU3.childImageSharp.fluid
  const KirimImg4 = isEnglish
    ? KUEN4.childImageSharp.fluid
    : KU4.childImageSharp.fluid
  const KirimImg5 = isEnglish
    ? KUEN5.childImageSharp.fluid
    : KU5.childImageSharp.fluid
  const KirimImg6 = isEnglish
    ? KUEN6.childImageSharp.fluid
    : KU6.childImageSharp.fluid
  const KirimImg7 = isEnglish
    ? KUEN7.childImageSharp.fluid
    : KU7.childImageSharp.fluid
  const KirimImg8 = isEnglish
    ? KUEN8.childImageSharp.fluid
    : KU8.childImageSharp.fluid
  const KirimImg9 = isEnglish
    ? KUEN9.childImageSharp.fluid
    : KU9.childImageSharp.fluid

  const TagihanImg1 = isEnglish
    ? BTEN1.childImageSharp.fluid
    : BT1.childImageSharp.fluid
  const TagihanImg2 = isEnglish
    ? BTEN2.childImageSharp.fluid
    : BT2.childImageSharp.fluid
  const TagihanImg3 = isEnglish
    ? BTEN3.childImageSharp.fluid
    : BT3.childImageSharp.fluid
  const TagihanImg4 = isEnglish
    ? BTEN4.childImageSharp.fluid
    : BT4.childImageSharp.fluid
  const TagihanImg5 = isEnglish
    ? BTEN5.childImageSharp.fluid
    : BT5.childImageSharp.fluid
  const TagihanImg6 = isEnglish
    ? BTEN6.childImageSharp.fluid
    : BT6.childImageSharp.fluid
  const TagihanImg7 = isEnglish
    ? BTEN7.childImageSharp.fluid
    : BT7.childImageSharp.fluid

  const [defData, setDefData] = useState(data)
  const [imgSgUang, setImgSgUang] = useState(KirimImg1)
  const [imgSgTopUp, setImgSgTopUp] = useState(TopUpImg1)
  const [imgSgTagihan, setImgSgTagihan] = useState(TagihanImg1)
  const [activeUang, setActiveUang] = useState("0")
  const [activeTopUp, setActiveTopUp] = useState("0")
  const [activeTagihan, setActiveTagihan] = useState("0")

  useEffect(() => {
    const isUang = data[0] === "uang"
    const isTopUp = data[0] === "wallet"
    const isTagihan = data[0] === "tagihan"
    const value = data[1]
    setDefData(data)
    if (isUang) {
      setActiveUang(value)
      if (value === "0") setImgSgUang(KirimImg1)
      if (value === "1") setImgSgUang(KirimImg2)
      if (value === "2") setImgSgUang(KirimImg3)
      if (value === "3") setImgSgUang(KirimImg4)
      if (value === "4") setImgSgUang(KirimImg5)
      if (value === "5") setImgSgUang(KirimImg6)
      if (value === "6") setImgSgUang(KirimImg7)
      if (value === "7") setImgSgUang(KirimImg8)
      if (value === "8") setImgSgUang(KirimImg9)
    } else if (isTopUp) {
      setActiveTopUp(value)
      if (value === "0") setImgSgTopUp(TopUpImg1)
      if (value === "1") setImgSgTopUp(TopUpImg2)
      if (value === "2") setImgSgTopUp(TopUpImg3)
      if (value === "3") setImgSgTopUp(TopUpImg4)
      if (value === "4") setImgSgTopUp(TopUpImg5)
      if (value === "5") setImgSgTopUp(TopUpImg6)
    } else if (isTagihan) {
      setActiveTagihan(value)
      if (value === "0") setImgSgTagihan(TagihanImg1)
      if (value === "1") setImgSgTagihan(TagihanImg2)
      if (value === "2") setImgSgTagihan(TagihanImg3)
      if (value === "3") setImgSgTagihan(TagihanImg4)
      if (value === "4") setImgSgTagihan(TagihanImg5)
      if (value === "5") setImgSgTagihan(TagihanImg6)
      if (value === "6") setImgSgTagihan(TagihanImg7)
    }
  }, [data])

  useEffect(() => {
    if (changeParent === "uang") {
      setDefData(["uang", activeUang])
    }
    if (changeParent === "tagihan") {
      setDefData(["tagihan", activeTagihan])
    }
    if (changeParent === "wallet") {
      setDefData(["wallet", activeTopUp])
    }
  }, [changeParent])

  const isUang = defData[0] === "uang"
  const isTopUp = defData[0] === "wallet"
  const isTagihan = defData[0] === "tagihan"

  return (
    <>
      {/* <CustomDot /> */}
      {isUang && (
        <CustomImg
          fluid={imgSgUang}
          alt="How To Transfer"
          fadeIn
          loading="eager"
        />
      )}
      {isTopUp && (
        <CustomImg
          fluid={imgSgTopUp}
          alt="How To Top Up"
          fadeIn
          loading="eager"
        />
      )}
      {isTagihan && (
        <CustomImg
          fluid={imgSgTagihan}
          alt="How To Pay Bills"
          fadeIn
          loading="eager"
        />
      )}
    </>
  )
}

export const SgHowToImage = () => {
  const data = useStaticQuery(graphql`
    query {
      KU1: file(relativePath: { eq: "KU-1.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      KU2: file(relativePath: { eq: "KU-2.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      KU3: file(relativePath: { eq: "KU-3.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      KU4: file(relativePath: { eq: "KU-4.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      KU5: file(relativePath: { eq: "KU-5.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      KU6: file(relativePath: { eq: "KU-6.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      KU7: file(relativePath: { eq: "KU-7.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      KU8: file(relativePath: { eq: "KU-8.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      KU9: file(relativePath: { eq: "KU-9.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      KUEN1: file(relativePath: { eq: "KU-EN-1.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      KUEN2: file(relativePath: { eq: "KU-EN-2.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      KUEN3: file(relativePath: { eq: "KU-EN-3.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      KUEN4: file(relativePath: { eq: "KU-EN-4.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      KUEN5: file(relativePath: { eq: "KU-EN-5.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      KUEN6: file(relativePath: { eq: "KU-EN-6.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      KUEN7: file(relativePath: { eq: "KU-EN-7.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      KUEN8: file(relativePath: { eq: "KU-EN-8.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      KUEN9: file(relativePath: { eq: "KU-EN-9.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      BT1: file(relativePath: { eq: "BT-1.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      BT2: file(relativePath: { eq: "BT-2.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      BT3: file(relativePath: { eq: "BT-3.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      BT4: file(relativePath: { eq: "BT-4.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      BT5: file(relativePath: { eq: "BT-5.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      BT6: file(relativePath: { eq: "BT-6.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      BT7: file(relativePath: { eq: "BT-7.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      BTEN1: file(relativePath: { eq: "BT-EN-1.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      BTEN2: file(relativePath: { eq: "BT-EN-2.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      BTEN3: file(relativePath: { eq: "BT-EN-3.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      BTEN4: file(relativePath: { eq: "BT-EN-4.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      BTEN5: file(relativePath: { eq: "BT-EN-5.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      BTEN6: file(relativePath: { eq: "BT-EN-6.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      BTEN7: file(relativePath: { eq: "BT-EN-7.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      TU1: file(relativePath: { eq: "TU-1.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      TU2: file(relativePath: { eq: "TU-2.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      TU3: file(relativePath: { eq: "TU-3.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      TU4: file(relativePath: { eq: "TU-4.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      TU5: file(relativePath: { eq: "TU-5.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      TU6: file(relativePath: { eq: "TU-6.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      TUEN1: file(relativePath: { eq: "TU-EN-1.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      TUEN2: file(relativePath: { eq: "TU-EN-2.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      TUEN3: file(relativePath: { eq: "TU-EN-3.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      TUEN4: file(relativePath: { eq: "TU-EN-4.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      TUEN5: file(relativePath: { eq: "TU-EN-5.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      TUEN6: file(relativePath: { eq: "TU-EN-6.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return data
}

const CustomImg = styled(Img)`
  width: 80%;
  margin-left: 20%;
  @media (max-width: 768px) {
    display: none;
  }
`

const CustomDot = styled.div`
  position: absolute;
  width: 150px;
  height: 150px;
  background-color: #ffd27c;
  border-radius: 50%;
  top: 20%;
  @media (max-width: 768px) {
    display: none;
  }
`
